<template>
    <v-container fluid>
        <v-layout align-center justify-center style="padding-top: 5%">
            <v-col cols="12"
                lg="3"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="saveNewPassword('form-reset-password')" data-vv-scope="form-reset-password" autocomplete="off">
                    <v-row>
                        <v-col cols="12" style="text-align: center;">
                            <img src="@/assets/images/logo.png" style="height: 100px;" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" style="padding-top: 50px;"></v-col>
                    </v-row>
                    <v-row style="height: 130px;">
                        <v-col cols="12">
                            <label>Sua nova senha</label>
                            <v-text-field
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                autocomplete="new-password"
                                :rules=[validations.password]
                                hint="Senha deve conter pelo menos 8 caracteres, 1 letra maiúscula e 1 letra minúscula"
                                single-line
                                outlined
                                loading
                                @click:append="showPassword = !showPassword"
                            >
                                <template v-slot:progress>
                                    <v-progress-linear
                                        :value="progress"
                                        :color="color"
                                        absolute
                                        height="7"
                                    >
                                    </v-progress-linear>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <label>Confirmar nova senha</label>
                            <v-text-field
                                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                v-model="confirmPassword"
                                autocomplete="new-password"
                                :rules=[validations.samePassword(password,confirmPassword)]
                                single-line
                                outlined
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" style="text-align: center;">
                            <v-btn block dark type="submit" :disabled="!disableButton" :loading="loading">Confirmar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-layout>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required, validPassword } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import Vue from "vue";

    export default ({

        mixins: [Helpers],

        props: {
            token: {
                type: String,
                default: ""
            }
        },

        data: () => ({

            loading: false,
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,

            validations: {
                required: required,
                password: validPassword,
                samePassword (password, confirmPassword) {
                    if (password !== confirmPassword) {
                        return "Senhas não conferem!"
                    }
                    else
                        return true;
                }
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            progress () {
                return Math.min(100, this.password.length * 13)
            },

            color () {
                return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
            },

            disableButton() {
                return this.password !== "" && this.confirmPassword !== "" && this.validForm();
            }
        },

        methods: {

            validForm() {

                var isValidPass = true;

                if (this.password != "" || this.confirmPassword != "")
                {
                    isValidPass = validPassword(this.password) === true && this.password === this.confirmPassword;
                }

                var isValidFrm = isValidPass === true;

                return isValidFrm;
            },

            async revalidateToken() {

                try {
                    let validToken = await Vue.prototype.$spTechApi.get(`login/validate-token/${this.token}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (validToken.status !== 200) {
                        this.showToast("error", this.$t('aviso'), "Token inválido!");
                        this.$router.push({ path: "/login" });
                    }
                }
                catch {
                    this.showToast("error", this.$t('aviso'), "Token inválido!");
                    this.$router.push({ path: "/login" });
                }
            },

            async saveNewPassword() {

                if (this.validForm() === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    let requestNewPassword = {
                        tokenRecoveryPassword: this.token, 
                        password: this.password
                    }

                    const result = await this.$store.dispatch("usuarioModule/ResetSenha", requestNewPassword);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.$router.push({ path: "/login" });
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        mounted() {
            this.revalidateToken();
            if (this.token == "" || this.token == undefined || this.token == null) {
                this.$router.push({ path: "/login" });
            }
        }
    })
</script>
